@import 'styles/variables';
@import 'styles/functions';
@import 'styles/mixins';

.goBackBtn {
  font-size: $font-size-xs;
  font-style: italic;
  letter-spacing: 0.05em;
  text-decoration-line: underline;
}

.selectedImage {
  @include responsive-ratio(10, 16);

  img {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.selectedImageZoom {
  position: absolute;
  top: spacing(1);
  right: spacing(1);
  z-index: 1;
}

.bordered {
  border-top: 1px solid $gray-3;
  border-bottom: 1px solid $gray-3;
  @include py(3);
}

.colorPoint {
  @include circle(16);

  &:not(:last-child) {
    margin-right: spacing(1);
  }
}

.selectedImageInfo {
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 1;
  background-color: $common-white;
  text-transform: capitalize;
  font-size: $font-size-xs;
  padding: spacing(2);
}

.selectedImageInfoTitle {
  position: relative;
  font-size: $font-size-small;
  font-weight: 600;
  padding-left: spacing(1.5);
  margin-bottom: spacing(1);

  &::before {
    content: '';
    position: absolute;
    transform: translateY(-50%);
    top: 50%;
    left: 0;
    width: 5px;
    height: 5px;
    background-color: $secondary-color;
    border-radius: 50%;
  }
}
