@import 'styles/variables';
@import 'styles/functions';
@import 'styles/mixins';

.root {
  display: flex;
  background: #FFFFFF;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  padding: spacing(1.5);
  min-height: 100px;
}

.avatar {
  @include circle(72);
  margin-right: spacing(2.5);
}

.userInfo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-grow: 1;
  margin-right: spacing(3);
  overflow: hidden;

  .title {
    @include font-title();
    margin-bottom: spacing(1);
    font-size: $font-size-big;
    line-height: 1.2;
    font-weight: normal;
  }

  .description {
    font-weight: 600;
    font-size: $font-size-xs;
    line-height: 1.4;
  }
}

.followBtn {
  min-width: 105px;

  &.followed {
    display: flex;
    align-items: center;

    &:hover {
      box-shadow: none;
    }
  }
}
