@import 'styles/variables';
@import 'styles/functions';
@import 'styles/mixins';

.root {
    padding: spacing(6.5) spacing(3);
    max-width: 100%;
    width: 1680px;
    margin: 0 auto;
}

.title {
    @include font-title();
    font-size: 24px;
    font-weight: 300;
    line-height: 1.3;
    text-transform: uppercase;
    margin-bottom: spacing(1);
}

.description {
    font-style: italic;
    line-height: 1.7;
}

.container {
    background-color: $common-white;
    padding: spacing(5) spacing(5) spacing(10);
}

.btnCode {
    font-weight: 300;
    line-height: 1.3;
}

.btnCodeTitle {
    font-weight: 600;
    font-size: $font-size-main;
}

.listContainer {
    width: 45%;
    padding-left: spacing(1);
}

.list {
    padding: 0;
    margin: spacing(3) 0 0 0;
    list-style-type: none;
    font-style: italic;

    li {
        position: relative;
        padding-left: spacing(2.5);
        line-height: 1.6;

        &:not(:last-child) {
            margin-bottom: spacing(1.5);
        }

        &::after {
            content: '';
            position: absolute;
            top: 8px;
            left: 0;
            width: 8px;
            height: 8px;
            border-radius: 50%;
            background-color: $secondary-color;
        }
    }
}

.banner {
    position: relative;

    img {
        position: relative;
        z-index: 1;
        display: block;
        margin-left: auto;
    }
}

.bannerShape {
    position: absolute;
    z-index: 0;
    top: 0;
    left: 0;
    width: 90%;
    height: 100%;
    background-color: $gray-2;
    opacity: 0.5;
    clip-path: polygon(0 0, 100% 45%, 100% 100%, 0% 100%);
}

.bannerAuthor {
    font-style: italic;
    font-size: 10px;
    text-transform: uppercase;
    line-height: 1.6;
}

.bannerQuote {
    @include font-title();
    text-transform: uppercase;
    line-height: 1.7;
    font-size: $font-size-xs;
    margin-bottom: spacing(2);
}

.video {
    position: relative;
    overflow: hidden;

    img {
        width: 100%;
        transition: transform 0.25s ease;
    }

    &:hover {
        img {
            transform: scale(1.1);
        }
    }
}

.videoText {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.videoTitle {
    color: $common-white;
    font-size: 36px;
    line-height: 1.3;
    margin-bottom: spacing(5);
}

.videoLink {
    color: $common-white;

    &:hover {
        color: $primary-color;
    }

    svg {
        width: 73px;
        height: 73px;
    }
}