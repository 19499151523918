@import 'styles/variables';
@import 'styles/functions';
@import 'styles/mixins';

.root {
    background-color: $common-white;
    padding: spacing(17.75) 0 spacing(10);
}

.title {
    @include font-title();
    font-size: 42px;
    line-height: 1.2;
    font-weight: normal;
    position: relative;
    padding: 0 spacing(3);
    margin-bottom: spacing(4);

    &::before, &::after {
        content: '';
        position: absolute;
        transform: translateY(-50%);
        top: 50%;
        width: 100px;
        height: 2px;
        background-color: $primary-color;
    }

    &::before {
        right: 100%;
    
    }

    &::after {
        left: 100%;
    }
}

.description {
    color: $gray-6;
    font-style: italic;
    line-height: 1.8;
    margin-bottom: spacing(16.25);
    font-size: $font-size-big;
}

.section {
    display: flex;

    &:not(:last-child) {
        margin-bottom: spacing(18.75);
    }
}

.sectionGet {
    .sectionAbout {
        margin-top: spacing(8.5);
        margin-left: spacing(22.25);
    }
}

.sectionRequest {
    .sectionAbout {
        margin-top: spacing(8.5);
        margin-right: spacing(10);
    }
}

.sectionCurate {
    .sectionAbout {
        margin-top: spacing(31.5);
    }
}

.sectionCompare {
    .sectionAbout {
        margin-top: spacing(19.25);
        margin-right: spacing(22.75);
    }
}

.sectionShop {
    .sectionAbout {
        margin-top: spacing(1);
        margin-left: spacing(-12);
    }
}


.sectionTitle {
    font-size: 42px;
    line-height: 1.2;
    margin-bottom: spacing(5);

    &::after {
        content: '';
        display: block;
        width: 33px;
        height: 3px;
        background-color: $secondary-color;
        border-radius: 4px;
    }
    
    span {
        display: block;
        margin-bottom: spacing(5);
        color: $secondary-color;
    }
}

.sectionDescription {
    font-size: 24px;
    font-style: italic;
    line-height: 2;
}