@import 'styles/variables';
@import 'styles/functions';
@import 'styles/mixins';

@mixin hidden() {
    left: -100%;
    transform: translateX(-100%);
}

@mixin visible() {
    left: 100%;
    transform: translateX(0);
}

@keyframes slideIn {
    from {
        @include hidden();
    }

    to {
        @include visible();
    }
}

@keyframes slideOut {
    from {
        @include visible();
    }

    to {
        @include hidden();
    }
}

.root {
    position: absolute;
    top: 0;
    @include hidden();
    height: 100%;
    width: 370px;
    min-width: 370px;
    padding: spacing(3) spacing(5) spacing(3) spacing(3);
    background-color: $gray-2;
    z-index: 101;
    animation-name: slideIn;
    animation-duration: 0.4s;
    animation-fill-mode: forwards;
    display: flex;
    flex-direction: column;

    &.onClose {
        animation-name: slideOut;
    }
}

.closeBtn {
    position: absolute;
    top: 26px;
    right: 22px;
    padding: 0;
    border: none;
    background: none;
    color: $gray-1;
    transition: color .25s ease;
    cursor: pointer;

    &:hover {
        color: $text-color;
    }
}

.title {
    @include font-title();
    font-size: 24px;
    margin-bottom: spacing(4);

    span {
        color: $primary-color;
    }
}

.icon {
    @include circle(45);
    background-color: $common-white;
    color: $primary-color;
    margin-right: spacing(2);
}

.itemDescription {
    font-style: italic;
    font-size: $font-size-xs;
    line-height: 2;
}