@import 'styles/variables';
@import 'styles/functions';
@import 'styles/mixins';

.styleQuizArea {
  background-color: $common-white;
  @include py(2);
  padding-left: spacing(9);
}

.leftPanelBg {
  position: absolute;
  width: 370px;
  background-color: $gray-2;
  height: 100%;
  z-index: -1;
}

.leftPanelWrapper {
  display: flex;
  flex-direction: column;
  min-width: 370px;
  width: 370px;
  @include px(3);
}

.mainContainer {
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
  @include px(3);
}

.sliderControl {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.viewAllBtn {
  position: absolute;
  bottom: -40px;
  right: 0;
}

.hoveredItem {
  box-shadow: 0 0 0 1200px rgba(0,0,0,0.2);
  z-index: 1002;
}
