@import 'styles/variables';
@import 'styles/functions';

.inputRoot {
    &[class*=MuiInput-root] {
        input.input:first-child {
            padding: spacing(1.25) 0 spacing(1.25) spacing(2.75);
        } 
    }
}

.icon {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
}

.clearIndicator {
    width: spacing(3.25);
    height: spacing(3.25);
    background-color: $app-bg;
}

.clearIcon {
    color: $gray-4;
}

.option {
    padding: spacing(2);
    font-size: $font-size-xs;
    font-weight: 600;
    position: relative;

    &[aria-selected=true] {
        background-color: $common-white;
    }

    &[data-focus=true] {
        background-color: $gray-2;
    }

    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 2px;
        height: 100%;
        background-color: $primary-color;
        border-radius: 2px;
        visibility: hidden;
        opacity: 0;
    }

    &:hover {
        &::before {
            visibility: visible;
            opacity: 1;
        }
    }
}