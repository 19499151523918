@import 'styles/variables';
@import 'styles/functions';
@import 'styles/mixins';

.root {
  padding: spacing(6);
}

.title {
  @include font-title();
  font-size: 24px;
  line-height: 1.2;
  text-align: center;
  margin-bottom: spacing(5);
  text-transform: uppercase;
}
