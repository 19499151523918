@import 'styles/variables';
@import 'styles/functions';

.root {
  width: 960px;
  padding: spacing(6);
}

.textArea {
  height: 220px;
  border: none;
  border-top: 1px solid $gray-3;
}

.submitBtnContainer {
  width: 120px;
}
