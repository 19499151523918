@import 'styles/variables';
@import 'styles/functions';

.pickerTitle {
  font-weight: 600;
  font-size: $font-size-big;
}

.colorsContainer {
  min-height: spacing(4);
}

.selectedColor:not(:last-child) {
  margin-right: spacing(1.5);
}
