@import 'styles/functions';
@import 'styles/mixins';

.root {
    margin: 0 auto;
    padding: spacing(5);
}

.title {
    @include font-title();
    font-size: 30px;
}