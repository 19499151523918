@import 'styles/variables';
@import 'styles/functions';
@import 'styles/mixins';

.stylesContainer {
  margin-bottom: -(spacing(3));
}

.imgPreviewModal {
  padding: 0;

  img {
    max-width: 960px;
  }
}

.roomTypeContainer {
  margin-bottom: -(spacing(5));
}

.priceOptionIcon {
  @include circle(72);
  border: 2px solid $secondary-color;
  color: $secondary-color;
  margin-bottom: spacing(2);
  user-select: none;

  &.selected {
    background-color: $secondary-color;
    color: $common-white;
  }
}

.priceOptionTitle {
  @include font-title();
  font-size: $font-size-small;
  text-transform: uppercase;
  user-select: none;
}
