.root {
  margin-left: -6px;
  margin-right: -6px;
}

.colorPoint {
  margin-right: 16px;
  margin-bottom: 16px;

  &:nth-child(5n) {
    margin-right: 0;
  }
}
