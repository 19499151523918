@import 'styles/variables';
@import 'styles/functions';
@import 'styles/mixins';

.root {
  position: relative;
  @include responsive-ratio(1, 1);
  //cursor: grab;
}

.productLink {
  position: absolute;
  bottom: spacing(1);
  left: spacing(1);
  width: 100%;
}
