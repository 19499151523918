@import 'styles/variables';
@import 'styles/functions';

.inputWrapper {
  border: none;
  padding: 0;
  border-radius: 100px;
  background: $gray-2;
}

.input {
  margin: 0 spacing(3);
  padding: spacing(1.25) 0;
  border: none;
  font-size: $font-size-small;
  font-family: $font-family-main;
  font-weight: 600;
  background: $gray-2;

  &::placeholder {
    font-weight: normal;
    color: $gray-1;
  }
}
