@import 'styles/mixins';
@import 'styles/variables';

.root {
    border-radius: 0;
    padding: spacing(5) spacing(2);
}

.title {
    @include font-title();
    font-size: 30px;
    font-weight: normal;
    line-height: 1.3;
    margin-bottom: spacing(2);
}

.description {
    position: relative;
    color: $gray-6;
    font-size: $font-size-small;
    font-style: italic;
    line-height: 1.8;
    width: 45%;
    max-width: 45%;
    margin-bottom: spacing(2);
}