@import 'styles/variables';
@import 'styles/functions';
@import 'styles/mixins';

.backdrop {
  z-index: 1201;
  @include flex-center();
}

.pinCard {
  @include flex-center();
  flex-direction: column;
  width: 300px;
  height: 383px;
  background: $common-white;
  box-shadow: 10px 10px 30px rgba(0, 0, 0, 0.15);
  border-radius: 30px;
  padding: spacing(3);
  cursor: pointer;
}

.iconContainer {
  @include circle(140);
  background-color: $secondary-color;
  color: $common-white;
  margin-bottom: spacing(4);
}

.icon {
  width: spacing(7);
  height: spacing(7);

}

.title {
  font-size: 24px;
  line-height: 1.4;
  text-align: center;
}
