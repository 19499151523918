@import 'styles/variables';
@import 'styles/functions';
@import 'styles/mixins';

.section {
  position: relative;
  z-index: 1;
  background-color: $common-white;
  padding: spacing(8);
  margin-bottom: spacing(5);
}

.editBtn {
  position: absolute;
  right: spacing(2);
  top: spacing(2);
}

.title {
  @include font-title();
  font-size: 24px;
  line-height: 1.3;
  text-transform: uppercase;
}

.avatarContainer {
  margin-left: spacing(8);
}

.halfWidthContainer {
  width: calc(50% - #{spacing(7)});
}

.oneThirdWidthContainer {
  width: 30%;
}

.deactivateBtn {
  background-color: $error-color;
  color: $common-white;
}

.deactivateDescription {
  font-size: $font-size-xs;
  line-height: 1.7;
  font-style: italic;
  max-width: 50%;
 }

.disabledInput {
  color: $text-color !important;
}
