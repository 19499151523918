@import 'styles/mixins';
@import 'styles/variables';

.root {
    border-radius: 0;
    padding: spacing(2);
}

.title {
    @include font-title();
    font-size: 30px;
    font-weight: normal;
    line-height: 1.3;
}

.cardWrapper {
    padding: 0 spacing(1.75);
    display: flex;
    flex: 1;
}

.shadow {
    box-shadow: 10px 10px 40px rgba(194, 194, 194, 0.25);
}

.cardContent {
    display: flex;
    flex-direction: column;
    flex: 1;
}

.btnContainer {
    margin-top: auto;
    display: flex;
    justify-content: center;
}

.titleSm {
    @include font-title();
    font-size: 10px;
    line-height: 1.4;
    margin: spacing(2) 0 spacing(1); 
}

.btnList {
    list-style-type: none;
    padding: 0;
    margin: 0;

    li {
        display: inline-block;
        margin: 0 spacing(0.5);
    }
}

.btn {
    padding: spacing(0.75) spacing(1.5);
    font-size: 8px;
    color: $gray-1;
    background-color: $common-white;
    box-shadow: 0px 0px 40px #EBEBEB;
    border-radius: 200px;

    &.primary {
        background-color: $primary-color;
        color: $common-white;
    }
}

.icon {
    width: 10px;
    height: 10px;
    margin-right: 5px;
}

.rooms {
    list-style-type: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    li {
        padding: spacing(1);
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

.room {
    width: 145px;
    box-shadow: -20px 20px 40px rgba(0, 0, 0, 0.05);
}

.type {
    @include font-title();
    text-align: left;
    line-height: 1.5;
    background-color: $common-white;
    font-size: 8px;
    padding: spacing(0.75) spacing(1);
    display: flex;
    align-items: center;

    &::before {
        content: '';
        display: inline-block;
        border-radius: 50%;
        width: 9px;
        height: 9px;
        margin-right: spacing(0.75);
        border: 1px solid $primary-color;
    }
}

.img {
    position: relative;
    height: 90px;

    img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}