@import 'styles/variables';
@import 'styles/functions';

.root {
    width: 510px;
    background-color: $common-white;
}

.info {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 220px;
    background-color: $common-white;
    padding: spacing(3) spacing(2);
}

.title {
    font-size: 13px;
    line-height: 1.6;
    text-transform: capitalize;
    margin: spacing(0.5) 0 spacing(1);
}

.edit {
    position: absolute;
    bottom: spacing(2);
    right: spacing(2);
}