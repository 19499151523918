@import 'styles/variables';
@import 'styles/functions';

.root {
  border-right: 2px solid $error-color;

  &:not(:last-child) {
    margin-bottom: spacing(3);
  }
}
