@import 'styles/variables';
@import 'styles/functions';

.root {
  max-width: 385px;
}

.title {
  font-weight: 600;
  font-style: italic;
  line-height: 1.7;
  margin-bottom: spacing(2);
}

.description {
  font-size: $font-size-xs;
  font-style: italic;
  line-height: 1.8;
}
