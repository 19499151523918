@import 'styles/variables';
@import 'styles/functions';
@import 'styles/mixins';


.root {
    padding: spacing(2.5) spacing(4);
}

.title {
    @include font-title();
    font-size: 24px;
    font-weight: 300;
    margin-bottom: spacing(2);
}

.description {
    font-style: italic;
    line-height: 1.6;
    margin-bottom: spacing(3);
}

.link {
    color: $primary-color;
    text-decoration: underline;

    &:hover {
        text-decoration: none;
    }
}

.btn {
    background: none;
    border: none;
    padding: 0;
    font-family: $font-family-main;
    font-size: $font-size-main;
    font-style: italic;
    cursor: pointer;
}

.form {
    width: 270px;
    max-width: 100%;
}

.codeField {
    input {
        @include font-title();
        text-align: center;
        font-size: 36px;
        line-height: 1.7;
        font-weight: 300;
        color: $primary-color;
    }
}