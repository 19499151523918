@import 'styles/mixins';
@import 'styles/variables';

.root {
    display: flex;
    flex-direction: column;
    padding: spacing(3.75) spacing(2.5);
    background: $common-white;
    box-shadow: 10px 10px 40px rgba(194, 194, 194, 0.25);
}

.title {
    @include font-title();
    font-size: 20px;
    line-height: 1.3;
    font-weight: normal;
    text-align: center;
    position: relative;
    padding: 0 spacing(3);
    margin-bottom: spacing(1.5);

    &::before, &::after {
        content: '';
        position: absolute;
        transform: translateY(-50%);
        top: 50%;
        width: 36px;
        height: 1px;
        background-color: $primary-color;
    }

    &::before {
        right: 100%;
    
    }

    &::after {
        left: 100%;
    }
}

.description  {
    position: relative;
    color: $gray-6;
    font-size: $font-size-small;
    font-style: italic;
    line-height: 1.6;
    width: 85%;
    max-width: 85%;
    font-weight: 300;
}