@import 'styles/variables';
@import 'styles/functions';
@import 'styles/mixins';

.root {
    width: 1710px;
    margin: 0 auto;
    padding: spacing(5);
}

.title {
    font-size: 36px;
    line-height: 1.8;
}

.description {
    font-size: 24px;
    line-height: 1.8;
}

.aside {
    margin-left: auto;
}

.btn {
    padding: 0;
    background: none;
    border: none;
    @include font-title();
    font-size: 24px;
    line-height: 1.3;
    text-transform: capitalize;
    text-decoration: underline;
    cursor: pointer;
    color: $primary-color;
    margin-top: auto;

    &:hover {
        text-decoration: none;
    }
}