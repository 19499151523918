@import 'styles/variables';
@import 'styles/functions';
@import 'styles/mixins';

$icon-size: 55px;

.root {
  position: relative;
  min-height: 460px;
  @include flex-center();
  flex-direction: column;
}

.dropZone {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: $gray-1;
}

.uploadIcon {
  width: $icon-size;
  height: $icon-size;
}

.uploadText {
  font-size: 24px;
  font-weight: 600;
  line-height: 1.5;
  text-align: center;
  width: 520px;
}

.uploadBtn {
  position: absolute;
  bottom: spacing(3);
  width: 180px;
}

.previewZone {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 343px;
  margin-bottom: spacing(3);
}

.previewImage {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

.nextBtn {
  width: 180px;
}
