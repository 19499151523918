@import 'styles/variables';
@import 'styles/functions';
@import 'styles/mixins';

.uploadHandler {
  color: $primary-color;
  font-size: $font-size-small;
  font-style: italic;
  cursor: pointer;
}

.uploadHandlerBtn {
  display: flex;
  justify-content: center;
  align-items: center;
  @include circle(36);
  background-color: $gray-2;
  color: $primary-color;
}
