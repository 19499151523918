@import 'styles/variables';
@import 'styles/functions';
@import 'styles/mixins';

.selectColorSection {
  position: absolute;
  display: flex;
  align-items: center;
  padding: spacing(1) spacing(1) spacing(1) spacing(2);
  background-color: $common-white;
  color: $gray-6;
  right: 0;
  top: -57%;
  border-radius: 10px 10px 0 0;
}

.titleInput {
  background-color: transparent;
  border: none;
  @include font-title();
  color: $common-white;
  font-size: 30px;
  line-height: 1.1;
  text-transform: uppercase;
}
