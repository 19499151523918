@import 'styles/mixins';
@import 'styles/variables';

.root {
    width: 1300px;
    max-width: 100%;
}

.btn {
    border-radius: 200px;
    border: none;
    font-size: 14px;
    line-height: 1.2;
    color: $gray-1;
    background-color: $common-white;
    padding: spacing(1.5) spacing(2);
    margin: 0 spacing(0.75);
    cursor: pointer;

    &.btnActive, &:hover {
        color:$common-white;
        background-color: $primary-color;
        transition: all .25s ease;
    }
}

.tabContent {
    height: 400px;
}