@import 'styles/variables';
@import 'styles/functions';
@import 'styles/mixins';

.root {
    padding: spacing(6.5) spacing(18);
}

.backLink {
    display: inline-flex;
    align-items: center;
    font-weight: 600;
    font-size: $font-size-small;
    color: $primary-color;

    &:hover {
        color: darken($primary-color, $amount: 10);
        .backLinkIcon {
            color: $common-white;
            background-color: $primary-color;
        }
    }
}

.backLinkIcon {
    @include circle(31);
    background-color: $common-white;
    margin-right: spacing(2);
    transition: all 0.25s ease;
}

.title {
    @include font-title();
    font-size: 24px;
    line-height: 1.3;
    font-weight: normal;
    text-transform: uppercase;
    margin-bottom: spacing(1);
}

.description {
    font-size: $font-size-xs;
    font-style: italic;
    line-height: 1.8;
    color: $gray-6;
}

.itemsWrapper {
    margin: 0 spacing(-3) ;
}

.faqItem {
    width: 25%;
    padding: spacing(3);
    line-height: 1.8;
}

.modalContent {
    font-size: $font-size-small;
    line-height: 1.8;
}

.modalBtn {
    border: none;
    background: none;
    padding: 0;
    text-decoration: underline;
    color: $primary-color;
    font-size: $font-size-small;
    font-weight: 600;
    cursor: pointer;

    &:hover {
        text-decoration: none;
    }
}