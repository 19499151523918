@import 'styles/variables';
@import 'styles/mixins';
@import 'styles/functions';

.avatar {
  @include circle(46);
  margin-right: spacing(1);
}

.fullName {
  font-weight: 600;
  font-size: $font-size-big;
  line-height: 1.7;
}

.date {
  font-style: italic;
  font-size: $font-size-xs;
  line-height: 1.7;
}
