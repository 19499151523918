@import 'styles/variables';
@import 'styles/mixins';

.root {
  font-size: $font-size-xs;
  text-align: right;
  line-height: 120%;
  color: $gray-1;
}

.error {
  color: $error-color;
}
