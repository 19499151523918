@import 'styles/variables';
@import 'styles/functions';
@import 'styles/mixins';

.title {
  @include font-title();
  font-size: $font-size-big;
  line-height: 1.4;
  text-align: center;
  text-transform: uppercase;
}

.subTitle {
  font-size: $font-size-small;
  font-style: italic;
  text-align: center;
  color: #828282;
}

