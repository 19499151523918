@import 'styles/variables';
@import 'styles/mixins';

.root {
  position: relative;
  width: 67%;
  @include py(4);
  @include px(2);
  text-align: center;
  text-transform: capitalize;
  line-height: 1.4;
  z-index: 2;
}

.topRightBorder {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 80px;
  border-top: 1px solid $common-white;
  border-right: 1px solid $common-white;
  z-index: 3;
}

.bottomLeftBorder {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 80px;
  border-bottom: 1px solid $common-white;
  border-left: 1px solid $common-white;
  z-index: 3;
}
