@import 'styles/variables';

.track {
  background: $gray-2;

  &, .progressTrack {
    height: 4px;
    border-radius: 10px;
  }

  .progressTrack {
    width: auto;
    min-width: 40px;
    background: $secondary-color;
    transition: all .5s ease-in-out;
  }
}
