@import 'styles/variables';
@import 'styles/functions';
@import 'styles/mixins';


.root {
  position: relative;
  background-color: $common-white;
  border: 1px solid $gray-2;
}

.title {
  color: $common-white;
  font-weight: 600;
  line-height: 1.2;
  font-size: $font-size-big;
  padding: spacing(2) spacing(3);
  text-transform: uppercase;
  @include font-title();
  text-align: center;
}

.imgContainer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.single {
  flex: 0 0 100%;
  max-width: 100%;
}

.double {
  flex: 0 0 50%;
  max-width: 50%;
}

.triple {
  flex: 0 0 33.33333%;
  max-width: 33.33333%;
}

.placeholderWrapper {
  padding: spacing(0.5);
}

.placeholder {
  height: 100%;
  @include flex-center();
  border: 1px solid $gray-2;
}

.placeholderIcon {
  width: 100%;
  color: $gray-3;
}

.bottomControls {
  position: absolute;
  display: flex;
  bottom: spacing(1);
  right: 0;
}

.staticOverlay {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  color: $common-white;
  z-index: 3;
}

.overlay {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 3;
  opacity: 1;
  transition: opacity .2s ease-out;

  &.hidden {
    opacity: 0;
    pointer-events: none;
  }
}
