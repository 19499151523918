@import 'styles/variables';
@import 'styles/functions';
@import 'styles/mixins';

.typeSelect {
  min-width: 350px;
}

.sortSelect {
  min-width: 220px;
}

.hoverOverlay {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  padding: spacing(1);
  background: rgba(0, 0, 0, 0.6);
  color: $common-white;

}

.hoverLabel {
  line-height: 1.3;
  text-align: center;
  text-transform: capitalize;
  cursor: pointer;
  @include px(5);
}
