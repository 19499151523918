@import 'styles/variables';
@import 'styles/functions';

.root {
  position: relative;
  padding: spacing(9) spacing(5) spacing(7);
  color: $common-white;

  img {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .content {
    position: relative;
    z-index: 1;
  }
}

.title {
  font-size: 36px;
  line-height: 1.3;
  font-weight: 600;
  text-align: center;
  margin-bottom: spacing(4);
}
