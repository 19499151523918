@import 'styles/variables';
@import 'styles/functions';
@import 'styles/mixins';

.root {
    background-color: $common-white;
    box-shadow: 20px 20px 40px rgba(0, 0, 0, 0.03);
    border-radius: 2px;
    padding: 0 spacing(6);
    display: flex;
}

.title {
    @include font-title();
    font-size: 36px;
    line-height: 1.3;
    text-transform: uppercase;
}

.steps {
    flex: 1 1 auto;
    padding: spacing(5) 0 spacing(3);
}

.step {
    max-width: 250px;

    img {
        width: 100px;
        height: 100px;
    }

    &:not(:last-child) {
        position: relative;

        &::after {
            content: '';
            position: absolute;
            top: 50px;
            left: 75%;
            width: 50%;
            height: 1px;
            border-bottom: 1px dashed $gray-3;
        }
    }
}

.stepTitle {
    @include font-title();
    line-height: 1.5;
    padding: 0 spacing(2.5);
}