@import 'styles/variables';
@import 'styles/mixins';

.popoverTabIcon {
  @include circle(28);
  background-color: $gray-2;
  color: $primary-color;

  &.selected {
    background-color: $primary-color;
    color: $common-white;
  }
}
