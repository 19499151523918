@import 'styles/variables';
@import 'styles/functions';
@import 'styles/mixins';


.root {
    padding: spacing(6);
    width: 1070px;
    margin-left: spacing(6);
    background-color: $common-white;
    box-shadow: 20px 20px 40px rgba(0, 0, 0, 0.03);
    border-radius: 2px;
    display: flex;
    flex-direction: column;
}

.title {
    @include font-title();
    font-size: 30px;
}

.underlineBtn {
    padding: 0;
    border: none;
    background: none;
    cursor: pointer;
    text-decoration: underline;

    &:hover {
        text-decoration: none;
    }
}

.formButtons {
    margin-top: auto;
}

.dashTitle {
    @include font-title();
    font-size: $font-size-big;
    line-height: 1.3;
    font-weight: normal;
    text-align: center;
    position: relative;
    padding: 0 spacing(1.5);
    text-transform: uppercase;
    margin-bottom: spacing(1.5);

    &::before, &::after {
        content: '';
        position: absolute;
        transform: translateY(-50%);
        top: 50%;
        width: 36px;
        height: 1px;
        background-color: $primary-color;
    }

    &::before {
        right: 100%;
    
    }

    &::after {
        left: 100%;
    }
}

.fakeInput {
    padding: spacing(1.25) 0;
    border-bottom: 1px solid $gray-3;
    margin-top: auto;
    margin-bottom: spacing(3);
    font-size: $font-size-small;
    font-weight: 600;
}

.formTitle {
    line-height: 1.75;
}