@import 'styles/variables';
@import 'styles/functions';
@import 'styles/mixins';

.root {
  padding: spacing(5) 140px;
}

.sectionTitle {
  @include font-title();
  font-size: 30px;
  text-transform: capitalize;
  margin-bottom: spacing(3);
}

.sectionSubTitle {
  font-size: $font-size-small;
  font-style: italic;
  margin-bottom: spacing(3);
}

.lookBoardSection {
  min-width: 530px;
  width: 530px;
}

.detailsSection {
  display: flex;
  background: $common-white;
  box-shadow: 0 0 40px rgba(0, 0, 0, 0.07);
  min-height: 500px;
}

.selectedImgContainer {
  min-width: 300px;
  width: 300px;
}

.imgWrapper {
  position: relative;
  @include responsive-ratio(2, 3);

  img {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .imgTitle {
    z-index: 10;
    position: absolute;
    bottom: 0;
    right: 0;
    background-color: $common-white;
    padding: spacing(1.5) spacing(2);
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 60%;

    .label {
      display: flex;
      align-items: center;
      font-size: $font-size-small;
      line-height: 1.5;
      font-weight: 600;
      text-transform: capitalize;
    }

    .marker {
      width: 6px;
      height: 6px;
      border-radius: 50%;
      background-color: $secondary-color;
      margin-right: spacing(0.5);
    }
  }
}

.lookBoardInfo {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  padding: spacing(4);
  font-size: $font-size-small;
}

.infoTitle {
  min-width: 130px;
  font-weight: 600;
  text-transform: capitalize;
}

.infoText {
  color: $gray-6;
}

.colorPoint {
  @include circle(16);

  &:not(:last-child) {
    margin-right: spacing(1);
  }
}

.editBtn {
  position: absolute;
  bottom: -(spacing(2));
  right: -(spacing(2));
}
