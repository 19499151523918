@import 'styles/variables';
@import 'styles/functions';
@import 'styles/mixins';

.root {
  position: relative;
  @include flex-center();
  width: 160px;
  height: 32px;
  background-color: #f2994a33;
  border-radius: 100px;
  font-size: $font-size-small;
  text-transform: capitalize;
  transition: all 0.5s ease-out;
  cursor: pointer;
}

.thumb {
  position: absolute;
  left: 2px;
  @include circle(28);
  background-color: #f2994a;
  color: $common-white;
  transform: translateX(0);
  transition: transform .5s ease-in-out;

  &.checked {
    transform: translateX(128px);
  }
}
