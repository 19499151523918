@import 'styles/variables';
@import 'styles/functions';

.root {
  background-color: $app-bg;
  border-radius: 50px;
  padding: spacing(1) spacing(2);
  font-size: $font-size-small;
  font-weight: 600;
  line-height: 1.3;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.icon {
  color: $gray-3;
  transition: transform .2s ease-in-out;
  transform: rotate(0deg);

  &.open {
    transform: rotate(180deg);
  }
}

.optionsPopover {
  margin-top: spacing(1);
  padding: spacing(1.5) spacing(3);
  border-radius: 10px;
  min-height: 100px;
  background-color: $common-white;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
}

.option {
  padding: spacing(1.5) 0;
  font-size: $font-size-small;
  cursor: pointer;

  &:not(:last-child) {
    border-bottom: 1px solid $gray-2;
  }
}
