@import 'styles/variables';
@import 'styles/functions';

.permissionsLabel {
  margin-bottom: spacing(1);
  font-family: $font-family-main;
  color: $gray-1;
}

.permissionsDescription {
  color: $text-color;
  font-size: $font-size-xs;
  line-height: 1.7;
  margin-bottom: spacing(2);
  font-family: $font-family-main;
}

.disabledInput {
  color: $text-color !important;
}

.submitBtnContainer {
  width: 100px;
}
