@import 'styles/variables';
@import 'styles/functions';
@import 'styles/mixins';


.root {
    p {
        line-height: 2.2;
    }
}

.title {
    @include font-title();
    line-height: 1.3;
    font-size: 24px;
    text-transform: uppercase;
    font-weight: 300;
    margin-bottom: spacing(5);
}

.banner {
    @include flex-center();
    padding: spacing(3);
    background-color: $common-white;
    border-bottom: 3px solid $primary-color;
    box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.05);
    margin-bottom: spacing(5);
}

.icon {
    @include circle(37);
    background-color: $primary-color;
    color: $common-white;
}

.content {
    display: flex;
    background-color: $common-white;
    box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.05);
}

.image {
    position: relative;
    min-height: 700px;
    width: 55%;
    max-width: 660px;

    img {
        position: absolute;
        object-fit: cover;
    }
}

.contentBlock {
    flex: 1;
    padding: spacing(6) spacing(6.5);
}

.contentTitle {
    @include font-title();
    line-height: 1.3;
    color: $primary-color;
    font-size: 24px;
    text-transform: uppercase;
    font-weight: 300;
    margin-bottom: spacing(1.5);
}

.list {
    padding: 0;
    margin: 0;
    list-style-type: none;
    margin-bottom: spacing(6);

    li {
        position: relative;
        padding-left: spacing(3.75);
        line-height: 2.2;

        &::after {
            content: '';
            position: absolute;
            top: spacing(1.5);
            left: 0;
            width: 12px;
            height: 12px;
            border-radius: 50%;
            background-color: $primary-color;
        }
    }
}

.sidebar {
    max-width: 370px;
    padding: spacing(5) spacing(3);
    background-color: $common-white;
    box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.05);
}

.sidebarList {
    list-style-type: none;
    padding: 0;
    margin: 0;

    li {
        line-height: 1.3;
        
        &:not(:last-child) {
            margin-bottom: spacing(6);
        }
    }
}

.sidebarLink {
    display: inline-block;
    position: relative;

    &:hover {
        .sidebarLinkBackdrop {
            opacity: 1;
        }
    }

    img {
        width: 216px;
        max-width: 100%;
    }
}

.sidebarLinkBackdrop {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    @include flex-center();
    text-align: center;
    padding: spacing(2) spacing(5);
    font-size: 24px;
    font-weight: 600;
    background: rgba(0, 0, 0, 0.6);
    color: $common-white;
    opacity: 0;
    transition: opacity .2s ease-out;
}