@import 'styles/functions';

.root {
  display: flex;
  justify-content: center;
  padding: spacing(0.5);
}

.image {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
