@import 'styles/variables';
@import 'styles/functions';
@import 'styles/mixins';

.root {
    position: relative;
    box-shadow: 10px 20px 60px rgba(0, 0, 0, 0.1);

    img {
        display: block;
        margin-left: auto;
        width: 65%;
    }
}

.shadowShape, .shape {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    background-color: $common-white;
}

.shape {
    clip-path: polygon(0 0, 100% 0%, 70% 100%, 0% 100%);
    width: 50%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 0 spacing(8);
}

.title {
    @include font-title();
    font-size: 42px;
    color: $primary-color;
    font-weight: normal;
    max-width: 80%;
    margin-bottom: spacing(2.5);
}

.description {
    font-size: 24px;
    font-style: italic;
    line-height: 1.6;
    color: $gray-6;
    margin-bottom: spacing(6.25);
}


.link {
    color: $primary-color;
    font-size: $font-size-big;
    line-height: 1.4;
    text-decoration: underline;

    &:hover {
        text-decoration: none;
    }
}

.shadowShape {
    width: 55%;
    opacity: 0.65;
    clip-path: polygon(0 0, 97% 0%, 70% 100%, 0% 100%);
}