@import 'styles/variables';
@import 'styles/functions';
@import 'styles/mixins';

$slide-width: 414px;
$slide-height: 366px;

.root {
  position: relative;
  width: 2000px;
  max-width: 100%;
  margin: auto;
}

.slideWrapper {
  padding: spacing(13.75) spacing(1.75);

  &:focus {
    outline: none;
  }
}

.slide {
  height: $slide-height;
  width: $slide-width;
  position: relative;
  display: flex;
  transition: all 0.25s ease;
  margin: 0 auto;
  cursor: pointer;

  &.active {
    z-index: 1;
    -ms-transform: scale(1.35);
    transform: scale(1.35);
    width: 100%;
    background: #ffffff;
    box-shadow: 20px 20px 20px rgba(0, 0, 0, 0.1);

    .slideImage {
      width: 50%;
    }

    .box {
      opacity: 1;
      transition: all 0.35s ease .25s;
    }
  }
}

.box {
  flex: 0 0 50%;
  width: 50%;
  max-width: 50%;
  margin-left: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: spacing(3) spacing(5);
  opacity: 0;
  transition: none;
}

.boxText {
  @include font-title();
  font-size: 24px;
  line-height: 1.4;
  margin-top: auto;
  position: relative;
  width: 90%;
  max-width: 90%;
}

.quote {
  color: $secondary-color;

  &.quoteBefore, &.quoteAfter {
    position: absolute;
  }

  &.quoteBefore {
    bottom: 100%;
    right: 100%;
    transform: translate(-50%, 25%);
  }

  &.quoteAfter {
    bottom: 0;
    right: 0;
    transform: translate(50%, 50%) rotate(180deg);
  }
}

.boxName {
  font-size: $font-size-small;
  margin-top: auto;
  font-weight: 600;
  display: flex;
  align-items: center;
  align-self: flex-start;

  &::before {
    content: '';
    display: inline-block;
    margin-right: spacing(2);
    width: 40px;
    height: 1px;
    background-color: $secondary-color;
  }
}

.slideImage {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  transition: all 0.25s ease;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.dots {
  margin-top: spacing(4);
  text-align: center;
}

.dotsInner {
  display: inline-block;
  border-bottom: 2px solid $gray-2;
}

.dot {
  @include font-title();
  color: $secondary-color;
  font-size: 30px;
  line-height: 1.4;
  background: none;
  border: none;
  outline: none;
  padding: 0;
  width: 104px;
  text-align: left;
  cursor: pointer;
  opacity: 0;
  border-bottom: 3px solid $secondary-color;
  margin-bottom: -2px;
  padding-bottom: spacing(1.25);

  &.active {
    opacity: 1;
  }

  &:hover:not(.active) {
    opacity: 0.65;
  }
}

.btn {
  background: none;
  border: none;
  outline: none;
  padding: 0;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 51px;
  height: 51px;
  background-color: $primary-color;
  border-radius: 50%;
  cursor: pointer;
  transition: background-color .25s ease;

  &:hover {
    background-color: darken($primary-color, $amount: 8);
  }

  svg {
    color: $common-white;
  }
}

.prevBtn {
  left: 40px;
}

.nextBtn {
  right: 40px;
}
