@import 'styles/variables';
@import 'styles/functions';
@import 'styles/mixins';

.section {
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  background-color: $common-white;
  padding: spacing(4);
  margin-bottom: spacing(5);
  min-height: 720px;
}

.imgTypeSelect {
  min-width: 300px;
}

.sortTypeSelect {
  min-width: 220px;
}

.infoIcon {
  position: absolute;
  bottom: spacing(1);
  right: spacing(1);
}
