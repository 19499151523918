@import 'styles/variables';
@import 'styles/functions';
@import 'styles/mixins';

$gray-btn-default: $gray-3;
$gray-btn-hover: $gray-4;

.root {
  padding: 0;
  cursor: pointer;
}

.xs {
  @include circle(24);
}

.sm {
  @include circle(32);
 }

.md {
  @include circle(40);
}

.lg {
  @include circle(52);
}

.primary {
  background-color: $primary-color;
  color: $common-white;
}

.secondary {
  background-color: $secondary-color;
  color: $common-white;
}

.grey {
  background-color: $gray-2;
  color: $gray-1;
}

.primary, .secondary, .grey {
  transition: all 150ms ease-in;

  &:disabled {
    border-color: $gray-2 !important;
    background: $gray-2 !important;
    color: $gray-1 !important;
    cursor: not-allowed;

    &:hover {
      box-shadow: none !important;
      border-color: $gray-btn-default !important;
      background-color: $gray-btn-default !important;
    }
  }
}

.invertedWhite {
  background-color: $common-white;

  &.primary {
    color: $primary-color;
  }

  &.secondary {
    color: $secondary-color;
  }
}

.invertedGrey {
  background-color: $gray-2;

  &.primary {
    color: $primary-color;
  }

  &.secondary {
    color: $secondary-color;
  }
}

.invertedWhite.primary,
.invertedGrey.primary {
  &:hover {
    background-color: $primary-color;
    color: $common-white;
  }
}

.invertedWhite.secondary,
.invertedGrey.secondary {
  &:hover {
    background-color: $secondary-color;
    color: $common-white;
  }
}


