@import 'styles/variables';
@import 'styles/functions';
@import 'styles/mixins';

$link-size: spacing(9);

.root {
  background-color: $common-white;
  position: relative;
  z-index: 1001;
}

.navLink {
  width: $link-size;
  height: $link-size;
  background-color: $common-white;
  @include flex-center();
  color: $gray-1;
  cursor: pointer;
  transition: all .15s ease-out;

  &.active {
    background-color: $secondary-color;
    color: $common-white;
  }

  &.activeTab {
    background-color: $gray-2;
    color: $secondary-color;
  }

  &.activeTabInfo {
    background-color: $secondary-color;
    color: $common-white;
  }
}

.ambassadorNavLink {
  position: relative;

  &::before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 5px;
    width: 1px;
    height: 100%;
    background-color: $secondary-color;
  }

  &:first-child {
    &::before {
      height: calc(100% - 6px);
    }
    &::after {
      content: '';
      position: absolute;
      top: 6px;
      left: 5px;
      width: calc(100% - 5px);
      height: 1px;
      background-color: $secondary-color;
    }
  }

  &:last-child {
    &::before {
      top: 0;
      height: calc(100% - 6px);
    }
    &::after {
      content: '';
      position: absolute;
      bottom: 6px;
      left: 5px;
      width: calc(100% - 5px);
      height: 1px;
      background-color: $secondary-color;
    }
  }
}

.miniTitle {
  position: absolute;
  top: 6px;
  right: 0;
  width: calc(100% - 6px);
  font-family: $font-family-title;
  font-size: 9px;
  padding: 1px 3px;
  background-color: $secondary-color;
  color: $common-white;
}