@import 'styles/functions';

.popover {
    margin-top: spacing(1);
    line-height: 2;
    font-style: italic;
    
    &.fullWidth {
        width: 100%;
    }

    &.xs {
        max-width: 444px;
    }

    &.sm {
        max-width: 600px;
    }

    &.md {
        max-width: 960px;
    }

    &.lg {
        max-width: 1280px;
    }

    &.xl {
        max-width: 1920px;
    }
}