@import 'styles/variables';
@import 'styles/functions';
@import 'styles/mixins';

$btn-color: #C1C2C6;

.root {
  padding: spacing(1);
  border-radius: 100px;
  background-color: $common-white;
  display: flex;
  margin-top: -12px;
}

.info {
  background-color: $btn-color;
  color: $common-white;
  @include circle(28);
}

.btn {
  background-color: $btn-color;
  color: $common-white;
  font-weight: 300;
  font-size: 10px;
  padding: spacing(0.5) spacing(1);
}

.getTheLookLink {
  min-width: 115px;
}
