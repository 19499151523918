@import 'styles/variables';
@import 'styles/functions';
@import 'styles/mixins';

.root {
  position: relative;
  display: flex;
  flex-direction: column;
  background: #FFFFFF;
  box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.07);
  border-radius: 2px;
  width: 100%;
  min-width: 840px;
  max-width: 840px;
}

.title {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 84px;
  padding: spacing(3) spacing(5);
  @include font-title();
  color: $common-white;
  font-size: 30px;
  line-height: 1.2;
  text-transform: uppercase;
}

.editBtn {
  position: absolute;
  top: spacing(1);
  right: spacing(1);
}

.emptyTemplate {
  flex: 1 1 auto;
  @include flex-center();
  font-size: 24px;
  line-height: 1.7;
  font-style: italic;
  color: $gray-1;
  text-align: center;
}

.droppableContainer {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  border-bottom: 1px solid $gray-2;

  &.single {

    .droppableItem {
      flex: 0 0 100%;
      max-width: 100%;
    }
  }

  &.double {

    .droppableItem {
      flex: 0 0 50%;
      max-width: 50%;
    }
  }

  &.triple {

    .droppableItem {
      flex: 0 0 33.33333%;
      max-width: 33.33333%;
    }
  }
}

.droppableItem {
  position: relative;
  border: 1px solid $gray-2;

  &.invisible {
    opacity: 0;
  }

}

.draggableItem {
  position: relative;
  width: 100%;
  height: 100%;

  img {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: contain;
    z-index: 1;
  }

  .removeBtn {
    position: absolute;
    top: spacing(1);
    right: spacing(1);
    z-index: 2;
  }
}

.infoBlock {
  position: absolute;
  left: spacing(3);
  top: spacing(3);
  display: flex;
  align-items: center;
  z-index: 10;

  .infoIcon {
    @include circle(24);
    background-color: $gray-2;
    color: $primary-color;
    margin-right: spacing(1.5);
  }
}

.infoPopup {
  width: 400px;
  margin-top: spacing(1);
  line-height: 2;
  font-style: italic;
}

.addMoreBtn {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 50%);
  z-index: 10;
}
