@import 'styles/variables';
@import 'styles/functions';
@import 'styles/mixins';

.quizTitle {
  font-family: $font-family-title;
  font-weight: normal;
  font-size: 20px;
  line-height: 1.4;
  text-transform: uppercase;
}

.stepTitle {
  flex-grow: 1;
  min-width: 16.6666%;
  font-size: $font-size-xs;
  line-height: 1.7;
}

.selectDescription {
  font-size: $font-size-small;
  font-style: italic;
  line-height: 2;
}

.stepContainer {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.submitBtn {
  max-width: 150px;
  margin: 0 auto;
}
