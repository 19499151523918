@import 'styles/variables';
@import 'styles/functions';
@import 'styles/mixins';

.requestBanner {
  display: flex;
  background: $common-white;
  box-shadow: 16px 16px 20px rgba(223, 223, 223, 0.25);
  border-radius: 0 0 10px 10px;
  padding: spacing(1);

  .bannerTitle {
    border-right: 1px solid $gray-2;
    line-height: 1.4;
    padding: spacing(1) spacing(2) spacing(1);
    @include flex-center();
    flex-direction: column;
  }
}

.pageTitle {
  @include font-title();
  font-size: 30px;
  text-transform: capitalize;
}

.tabBtn {
  border-radius: 200px;
  border: none;
  font-size: 14px;
  line-height: 1.2;
  color: $gray-1;
  background-color: $common-white;
  padding: spacing(1.5) spacing(2);
  cursor: pointer;
  transition: all .2s ease-out;

  &:not(:last-child) {
    margin-right: spacing(1);
  }

  &.active {
    color:$common-white;
    background-color: $primary-color;
  }
}
