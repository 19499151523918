@import 'styles/mixins';
@import 'styles/variables';

.root {
    background-repeat: no-repeat;
    background-size: contain;
    padding: spacing(10) 0 spacing(7.25);
    position: relative;
}

.tabsWrapper {
    display: flex;
    justify-content: center;
    padding: 0 spacing(2);
    margin: spacing(-2.75) 0 0 spacing(25);
}

.image {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 33.333%;
    max-width: 100%;
    padding: spacing(4.25) spacing(6.5);
    display: flex;
    justify-content: flex-end;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    z-index: -1;
}

.imageTitle {
    @include font-title();
    font-size: 36px;
    font-weight: normal;
    margin-bottom: spacing(2.25);
}

.list {
    list-style-type: none;
    padding: 0;
    margin: 0;
    font-style: italic;
    line-height: 1.6;

    li {
        margin-bottom: spacing(1.5);
    }
}

.color {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    margin: 0 spacing(0.75);
}

.title {
    @include font-title();
    font-size: 38px;
    font-weight: normal;
    line-height: 1.3;
    padding-bottom: spacing(4.5);
    position: relative;

    &::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        height: 1px;
        width: 100px;
        background-color: $primary-color;
    }
}

.mixMatchContainer {
    display: flex;
    padding: spacing(2.5) 0;
    background-color: $common-white;
}

.item {
    display: flex;
    flex: 0 0 25%;
    width: 25%;
    max-width: 25%;

    &:not(:last-child) {
        border-right: 1px solid $gray-2;
    }
}

.productStreamContainer {
    background-color: $common-white;
    padding: spacing(2.5) spacing(3.75);
}

.row {
    &:not(:last-child) {
        margin-bottom: spacing(2.5);

        .rowTitle {
            &::after {
                display: none;
            }
        }
    }
}

.rowTitle {
    position: relative;
    overflow: hidden;
    margin-bottom: spacing(1);

    span {
        position: relative;
        z-index: 1;
        background-color: $common-white;
        display: inline-block;
        padding-right: spacing(2);
    }

    &::after {
        content: '';
        position: absolute;
        top: 50%;
        left: 0;
        width: 100%;
        height: 1px;
        background-color: $gray-2;
    }
}

.rowImage {
    margin: auto 0;
    max-width: 100%;
    max-height: 110px;
    display: inline-block;
}