@import 'styles/variables';
@import 'styles/functions';
@import 'styles/mixins';

.backdrop {
  z-index: 1201;
  @include flex-center();
}

.pinCard {
  @include flex-center();
  flex-direction: column;
  width: 300px;
  height: 433px;
  background: $common-white;
  box-shadow: 10px 10px 30px rgba(0, 0, 0, 0.15);
  border-radius: 30px;
  padding: spacing(3);
}

.icon {
  width: 160px;
  height: 160px;
  margin-bottom: spacing(3);
}

.title {
  font-size: 22px;
  line-height: 1.6;
  text-align: center;
}
