@import 'variables';
@import 'mixins';

html {
  font-size: $font-size-main;
  font-family: $font-family-main;
  letter-spacing: 0.03em;
}

p, h1, h2, h3, h4, h5, h6 {
  margin: 0;
  font-weight: normal;
}

.font-semi-bold {
  font-weight: 600;
}

.text-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
}

.text-nowrap {
  white-space: nowrap;
}

.text-uppercase {
  text-transform: uppercase;
}

.text-capitalize {
  text-transform: capitalize;
}

.font-italic {
  font-style: italic;
}

.text-xs {
  font-size: $font-size-xs;
}

.text-sm {
  font-size: $font-size-small;
}

.text-lg {
  font-size: $font-size-big;
}

.text-gray {
  color: $gray-1;
}

.font-title {
  @include font-title();
}

.font-main {
  font-family: $font-family-main;
}


//Temporary classname
.title {
  @include font-title();
  font-size: 32px;
  font-weight: normal;
}
