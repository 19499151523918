@import 'styles/variables';
@import 'styles/functions';
@import 'styles/mixins';

.routeContainer {
  flex-grow: 1;
  padding: spacing(9) spacing(6) spacing(6) spacing(9);
}

.title {
  @include font-title();
  font-size: 24px;
  line-height: 1.3;
  text-transform: uppercase;
}

.rightSection {
  padding: 138px spacing(16) spacing(6) 0;
}

.bannersContainer {
  min-width: 370px;
}
