@import 'styles/variables';

.scrollbarTrack {
  background-color: $gray-2;
  position: absolute;
  right: 2px;
  bottom: 2px;
  top: 2px;
  border-radius: 3px;
}

.scrollbarThumb {
  cursor: pointer;
  border-radius: inherit;
  background-color: $secondary-color;
}
