@import 'styles/variables';
@import 'styles/functions';
@import 'styles/mixins';

.root {
    width: 370px;
    background-color: $common-white;
    box-shadow: 20px 20px 40px rgba(0, 0, 0, 0.03);
}

.info {
    color: $common-white;
    padding: spacing(5) spacing(6) spacing(7.5);
    position: relative;

    &::before, &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: $primary-color;
        clip-path: polygon(0 0, 100% 0, 100% 100%, 0 85%);
    }

    &::before {
        z-index: 1;
    }

    &::after {
        top: spacing(2);
        opacity: 0.65;
    }
}

.infoContent {
    position: relative;
    z-index: 2;
}

.title {
    @include font-title();
    font-size: 22px;
    line-height: 1.3;
}

.list {
    padding-left: spacing(2);
    margin: 0;
    font-size: $font-size-small;
    line-height: 1.6;

    li {
        margin-bottom: spacing(3);
    }
}

.dashedTitle {
    @include font-title();
    font-size: $font-size-small;
    line-height: 1.3;
    position: relative;
    padding: 0 spacing(1.5);
    margin-bottom: spacing(2.5);

    &::before, &::after {
        content: '';
        position: absolute;
        transform: translateY(-50%);
        top: 50%;
        width: 33px;
        height: 1px;
        background-color: $secondary-color;
    }

    &::before {
        right: 100%;
    
    }

    &::after {
        left: 100%;
    }
}

.cardRoot {
    box-shadow: 10px 10px 40px rgba(194, 194, 194, 0.25);
}

.cardContent {
    padding: spacing(0.5);
}

.cardTitle {
    font-size: 9px;
    padding: spacing(1) spacing(1.5);
    text-transform: uppercase;
}