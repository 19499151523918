@import 'styles/variables';
@import 'styles/mixins';

.root {
  display: flex;
  flex-direction: column;
  width: 450px;
}

.notificationThumbnail {
  @include circle(36);
  background-color: $error-color;
  color: $common-white;
  display: flex;
  align-items: center;
  justify-content: center;
}

.dialogLink {
  display: block;
  text-decoration-line: underline;
  color: $primary-color;
}
