@import 'styles/variables';
@import 'styles/functions';
@import 'styles/mixins';

.root {
  display: flex;
  height: 340px;
  background-color: $common-white;
}

.title {
  @include font-title();
  font-size: 30px;
  line-height: 1.5;
  opacity: 0.6;
  text-align: center;
}

.border {
  flex-grow: 1;
  border-top: 3px solid $primary-color;
}
