@import 'styles/variables';
@import 'styles/functions';

.root {
    display: flex;
    flex-direction: column;
    width: 700px;
    padding: spacing(3.5) spacing(6);
    background-color: $common-white;
    box-shadow: 20px 20px 40px rgba(0, 0, 0, 0.03);
    border-radius: 2px;
}

.title {
    font-size: 24px;
    line-height: 1.4;
    font-weight: 600;
    text-transform: capitalize;
    margin-bottom: spacing(6);
}

.list {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.listItem {
    display: flex;
    font-size: $font-size-small;
    margin-bottom: spacing(2.5);
    line-height: 1.3;

    b {
        width: 25%;
    }
}

.itemsList {
    list-style-type: none;
    padding: 0;
    margin: 0;

    li {
        position: relative;
        padding-left: spacing(2);
        line-height: 1.6;

        &::before {
            content: '';
            position: absolute;
            top: 9px;
            left: 0;
            width: 5px;
            height: 5px;
            border-radius: 50%;
            background-color: $secondary-color;
        }
    }
}