@import 'styles/variables';
@import 'styles/mixins';

.root {
  position: relative;
  display: flex;
  flex-direction: column;
  //width: 350px;
  //height: 380px;
  overflow: hidden;
  background-color: $common-white;
  cursor: pointer;
  user-select: none;
  z-index: 1;

  img {
    max-width: 100%;
    max-height: 100%;
  }
}

.productImage {
  width: 200px;
  height: 200px;
  object-fit: cover;
}

.bottomBar {
  @include px(1);
  background-color: $common-white;
}

.productLabel {
  height: 60px;
  border-bottom: 1px solid $gray-3;
}

.staticOverlay {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  color: $common-white;
  z-index: 3;
}

.overlay {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 3;
  opacity: 1;
  transition: opacity .2s ease-out;
}

.hoverOverLay {
  background: transparent;
  color: $text-color;
}

.blurred {
  filter: blur(8px);
}

.hidden {
  opacity: 0;
  pointer-events: none;
}
