@import 'styles/variables';

.label {
  font-weight: 600;
  display: block;
  color: #eb5757;
  transition: color 0.3s ease-out;

  &.checked {
    color: $primary-color;
  }
}

.switch {
  width: 56px;
  height: 28px;
  border-radius: 100px;
  position: relative;
  background: #eb57574d;
  transition: all 0.5s ease-out;
  cursor: pointer;

  .thumb {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    top: -1px;
    left: -1px;
    height: 30px;
    width: 30px;
    border-radius: 50%;
    background: #eb5757;
    transition: all 0.3s ease-out;
    color: $common-white;

    svg {
      transition: transform 0.3s ease-out;
    }
  }

  input:checked ~ & {
    background: #87bdc34d;

    .thumb {
      transform: translateX(28px);
      background: $primary-color;

      svg {
        transform: rotate(180deg);
      }
    }
  }
}
