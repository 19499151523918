@import 'styles/variables';
@import 'styles/functions';
@import 'styles/mixins';

.section {
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  background-color: $common-white;
  padding: spacing(8);
  margin-bottom: spacing(5);
  min-height: 720px;
}

.emptyText {
  font-size: 20px;
  line-height: 1.3;
}

.editBtn {
  position: absolute;
  right: spacing(2);
  top: spacing(2);
}

.preferenceTitle {
  font-weight: 600;
  line-height: 1.7;
  margin-bottom: spacing(1.5);
}

.styleCard {
  width: 280px;
  background-color: $common-white;
  box-shadow: -20px 20px 40px rgba(0, 0, 0, 0.05);
  margin-right: spacing(2);
  margin-bottom: spacing(2);
}

.stylesImgContainer {
  @include responsive-ratio(16, 10);

  img {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.roomTypeIcon {
  $icon-size: 50px;

  width: $icon-size;
  height: $icon-size;
  color: $secondary-color;
  margin-bottom: spacing(2.5);
}
