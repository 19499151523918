@import 'styles/variables';
@import 'styles/functions';
@import 'styles/mixins';

.root {
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: $common-white;
  padding: spacing(4);
}

.slideContainer:focus {
  outline: none;
}

.slide {
  margin: 0 auto;
  text-align: center;
  cursor: pointer;

  img {
    display: inline-block;
    margin: 0 auto;
    max-width: 100%;
    height: auto;
  }
}

.ratingIconEmpty {
  color: $secondary-color;
  opacity: 0.3;
}

.ratingIconFilled {
  color: $secondary-color;
}

.slideControl {
  @include circle(40);
  background: none;
  border: 2px solid $gray-1;
  color: $gray-1;
  cursor: pointer;
}

.prevSlide {
  transform: rotate(90deg);
}

.nextSlide {
  transform: rotate(-90deg);
}

.dot {
  @include circle(8);
  border: 1px solid $secondary-color;
  background: none;
  margin: 0 spacing(0.5);
  cursor: pointer;

  &.active {
    background: $secondary-color;
  }
}
