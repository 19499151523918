@import 'styles/variables';
@import 'styles/functions';
@import 'styles/mixins';

.root {
  background-color: $common-white;
  box-shadow: -20px 20px 40px rgba(0, 0, 0, 0.05);
  cursor: pointer;
  transition: all .15s ease-out;

  &.selected {
    background-color: $secondary-color;
    color: $common-white;

    .icon {
      color: $common-white;
    }
  }
}

.imgContainer {
  @include responsive-ratio(16, 10);

  img {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.description {
  font-size: $font-size-xs;
  font-style: italic;
  line-height: 1.8;
}

.icon {
  color: $secondary-color;
  transition: color .15s ease-out;
}
