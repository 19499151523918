@import 'styles/variables';
@import 'styles/functions';

.root {
    display: flex;
    border: 1px solid $primary-color;
    background-color: $common-white;
    line-height: 1.2;

    &:not(:last-child) {
        margin-bottom: spacing(2.25);
    }
}

.title {
    flex-grow: 1;
    padding: spacing(2.25) spacing(4);
    font-size: 20px;
    color: $primary-color;
}

.btn {
    text-transform: uppercase;
    font-size: 18px;
    color: $gray-2;
    border: none;
    background: none;
    cursor: pointer;
    background-color: $primary-color;
    padding: spacing(2.25);
    transition: background-color .25s ease;

    &:hover {
        background-color: darken($primary-color, $amount: 8);
    }
}