@import 'styles/variables';
@import 'styles/functions';
@import 'styles/mixins';


.imageContainer {
    flex: 1 1 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 24px;
    font-weight: 600;
    color: $gray-2;
    line-height: 1.5;
    text-align: center;
    padding: spacing(2);
    background: #FFFFFF;
    box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.07);
    border-radius: 2px;
    width: 840px;
    max-width: 100%;
    margin-top: spacing(7);
}

.requestType {
    display: flex;
    background-color: $common-white;
    opacity: 0.5;
    cursor: not-allowed;

    &.active:not(.selected) {
      opacity: 1;
      cursor: pointer;

      &:hover {    
        .requestTypeTitle {
            color: $primary-color;
        }

        .requestTypeContent::after {
            display: block;
        } 
    }
    }

    &.selected {
        color: $common-white;
        background-color: $primary-color;
        opacity: 1;
        cursor: auto;
    }
}

.requestTypeContent {
    position: relative;
    flex: 1;
    display: flex;
    flex-direction: column;
    padding: spacing(1.5) spacing(2);

    &::after {
        content: 'Select';
        position: absolute;
        bottom: spacing(3);
        right: spacing(2);
        transform: translateY(100%);
        text-decoration: underline;
        color: $primary-color;
        font-size: $font-size-xs;
        display: none;
    }
}

.requestTypeTitle {
    font-size: $font-size-small;
    line-height: 1.3;
    font-weight: 600;
    margin-bottom: spacing(1);
    display: flex;
}

.requestTypeTitleCircle {
    @include circle(13);
    background-color: $common-white;
    border: 1px solid $primary-color;
    margin: spacing(0.25) spacing(0.5) 0 0;

    svg {
        width: 6px;
        height: 4px;
    }
}

.requestTypeDescription {
    font-size: $font-size-xs;
    font-style: italic;
    letter-spacing: 0.07em;
    line-height: 1.6;
    margin-bottom: spacing(1);
}

.requestTypeBtn {
    margin-top: auto;
    margin-left: auto;
    padding: spacing(1) spacing(3);
    min-width: 100px;
    display: inline-block;
    text-align: center;
    color: $common-white;
    border: 1px solid $common-white;
    border-radius: 100px;

    &:hover {
        background-color: $common-white;
        color: $primary-color;
        border-color: $common-white;
    }
}