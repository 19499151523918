@import 'styles/variables';

$toastr-bg: $error-color;

$toastr-width: 200px !default;
$successBgColor: #9CE7CC !default;
$infoBgColor: $primary-color !default;
$warningBgColor: $secondary-color !default;
$errorBgColor: #FFB4B4 !default;
$defaultSpace: 10px;
