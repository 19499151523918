@import 'styles/mixins';

.hoverLabel {
  font-size: 24px;
  line-height: 1.3;
  font-weight: 600;
  text-align: center;
  text-transform: capitalize;
  cursor: pointer;
  @include px(5);
}
