@import 'styles/mixins';
@import 'styles/variables';

.hoverLabel {
  font-size: $font-size-small;
  line-height: 1.3;
  font-weight: 600;
  text-align: center;
  text-transform: capitalize;
  cursor: pointer;
  @include px(4);
}
