@import 'styles/variables';
@import 'styles/mixins';

.root {
  overflow: hidden;
  background-color: $common-white;
  user-select: none;
  z-index: 1;
  @include responsive-ratio(2, 3);
  border: 1px solid $gray-3;

  img {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 2;
  }
}

.overlay {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  color: $common-white;
  z-index: 3;
  opacity: 1;
  transition: opacity .2s ease-out;

  a {
    color: $common-white;
    line-height: 1.6;
  }
}

.blurred {
  filter: blur(8px);
}

.disabled {
  pointer-events: none;
}

.hidden {
  opacity: 0;
  pointer-events: none;
}


