@import 'styles/variables';
@import 'styles/mixins';

.root {
    display: flex;
    flex-direction: column;
}

.title {
    @include font-title();
    line-height: 1.4;
    background-color: $primary-color;
    color: $gray-2;
    padding: spacing(2);
    position: relative;
    z-index: 1;
}

.content {
    display: flex;
    align-items: center;
    flex: 1;
    position: relative;
    z-index: 1;
    padding: spacing(3);
    background-color: $common-white;
}