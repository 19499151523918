@import 'styles/variables';
@import 'styles/functions';
@import 'styles/mixins';

.content {
    padding: spacing(2);
}

.shadow {
    display: inline-block;
    box-shadow: 10px 10px 40px rgba(194, 194, 194, 0.25);
}

.cardWrapper {
    padding: 0 spacing(1.75);
    display: flex;
    flex: 1;
}

.title {
    @include font-title();
    color: $primary-color;
    font-weight: normal;
    font-size: 22px;
    line-height: 1.3;
    text-align: center;
}

.titleSm {
    @include font-title();
    font-size: 14px;
    line-height: 1.3;
    font-weight: normal;
    position: relative;
    padding: 0 spacing(1.5);
    margin-bottom: spacing(1.5);

    &::before, &::after {
        content: '';
        position: absolute;
        transform: translateY(-50%);
        top: 50%;
        width: 33px;
        height: 1px;
        background-color: $secondary-color;
    }

    &::before {
        right: 100%;
    
    }

    &::after {
        left: 100%;
    }
}