@import 'styles/variables';
@import 'styles/functions';
@import 'styles/mixins';

.filtersBlock {
  flex-grow: 1;
  opacity: 1;
  transition: opacity .2s ease-out;

  &.invisible {
    opacity: 0;
  }
}

.myImageBlock {
  display: flex;
  align-items: center;
  background-color: $common-white;
  border-radius: 100px;
  padding-right: 6px;
}

.myImageSelect {
  min-width: 53%;
  padding: spacing(1.75) spacing(2);
  font-size: $font-size-xs;
  background-color: $primary-color;
  color: $common-white;
}

.pinBtn {
  font-size: $font-size-xs;
  line-height: 1.15;
  font-weight: 300;
  transition: none;

  &:hover {
    .pinBtnIcon {
      color: $primary-color;
      background-color: $common-white;
    }
  }
}

.pinBtnIcon {
  @include circle(24);
  color: $common-white;
  background-color: $primary-color;
}
