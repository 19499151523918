@import 'styles/variables';
@import 'styles/functions';
@import 'styles/mixins';

.root {
  background-color: $common-white;
  padding: spacing(3) spacing(10);
}

.column {
  font-size: $font-size-small;
  font-style: italic;
  align-self: flex-start;
}

.list {
  padding: 0;
  margin: 0;
  list-style-type: none;
}

.horizontalDivider {
  width: 1px;
  height: 75%;
  background-color: $gray-3;
  margin: 0 spacing(12);
}

.socialLinksTitle {
  @include font-title();
  font-size: 20px;
  text-transform: uppercase;
  line-height: 1.2;
  margin-right: spacing(5);
}


.socialLink {
  @include circle(32);
  color: $primary-color;

  &:not(:last-child) {
    margin-right: spacing(2);
  }
  &:hover {
    color: darken($primary-color, $amount: 8);
  }
}
