@import 'styles/variables';
@import 'styles/functions';
@import 'styles/mixins';

.formContainer {
  flex-grow: 1;
  max-width: 480px;
  //height: 800px;
  padding: spacing(5) spacing(8);
}

.imgContainer {
  position: relative;

  img {
    width: 100%;
  }
}

.banner {
  $banner-margin: spacing(2.5);

  position: absolute;
  width: calc(100% - #{2 * $banner-margin});
  margin: 0 $banner-margin;
  padding: spacing(2) spacing(8);
  background-color: $common-white;
  bottom: $banner-margin;

  .bannerTitle {
    position: relative;
    background-color: $common-white;
    @include font-title();
    line-height: 1.3;
    text-transform: uppercase;
    padding: 0 spacing(2);
    z-index: 1;
  }

  .bannerSubtitle {
    font-size: $font-size-xs;
    font-style: italic;
    text-transform: capitalize;
    line-height: 1.2;
    text-align: center;
    color: #828282;
  }

  .crossline {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
    height: 2px;
    background-color: $secondary-color;
  }
}

.closeBtn {
  color: $common-white;
}
