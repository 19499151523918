@import 'styles/variables';
@import 'styles/functions';

$input-bg-color: $common-white;

.inputWrapper {
  position: relative;
  display: flex;
  align-items: center;
  padding: 0 12px;
  border-radius: 100px;
  background: $input-bg-color;
}

.searchIcon {
  color: $primary-color;
}

.input {
  width: 100%;
  padding: spacing(1.25) 0;
  border: none;
  font-size: $font-size-small;
  font-family: $font-family-main;
  font-weight: 600;
  background: $input-bg-color;

  &::placeholder {
    font-weight: normal;
    color: $gray-4;
  }
}

.closeIcon {
  color: $gray-3;
  cursor: pointer;
}
