@import 'styles/variables';
@import 'styles/functions';
@import 'styles/mixins';

.root {
  position: fixed;
  bottom: 130px;
  right: spacing(4);
  z-index: 100;
}

.actionBtn {
  @include circle(80);
  background-color: $primary-color;
  color: $common-white;
  cursor: pointer;
  box-shadow: 10px 10px 50px rgba(0, 0, 0, 0.15);
  margin-bottom: spacing(2.5);
}
