@import 'styles/variables';
@import 'styles/functions';
@import 'styles/mixins';

.root {
    padding: spacing(10) 0 spacing(12.5);
    background-color: $common-white;
}

.title {
    @include font-title();
    font-size: 36px;
    font-weight: normal;
    position: relative;
    padding: 0 spacing(3);
    margin-bottom: spacing(3);

    &::before, &::after {
        content: '';
        position: absolute;
        transform: translateY(-50%);
        top: 50%;
        width: 50px;
        height: 2px;
        background-color: $primary-color;
    }

    &::before {
        right: 100%;
    
    }

    &::after {
        left: 100%;
    }
}


.description {
    color: $gray-6;
    font-style: italic;
    line-height: 2;
}

.bannerWrapper {
    margin-top: spacing(12.5);
    width: 1360px;
    max-width: 100%;
    margin: spacing(12.5) auto 0;
}