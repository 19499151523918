@import 'styles/variables';
@import 'styles/functions';
@import 'styles/mixins';

.modalTitle {
  font-size: 24px;
  font-weight: 600;
  line-height: 1.4;
  margin-bottom: spacing(2);
}

.similarImagesBlock {
  min-width: 400px;
}

.similarImageContainer {
  margin-bottom: -28px !important;
}

.imageBlock {
  flex-grow: 1;
  width: 420px;
  min-width: 370px;
}

.currentImageContainer {
  position: relative;
  overflow: hidden;
  @include responsive-ratio(10, 16);

  img {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

.formBlock {
  flex-grow: 1;
  width: 420px;
  min-width: 370px;
}
