@import 'styles/variables';
@import 'styles/functions';

.root {
  min-width: 200px;
  padding: spacing(3);
  background-color: $common-white;
  border-radius: 4px 4px 0 0;
  box-shadow: 20px 20px 40px rgba(0, 0, 0, 0.03);
  font-size: $font-size-small;
  line-height: 1.3;
  text-align: center;
  cursor: pointer;

  &.active {
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.05);
    font-weight: 600;
    color: $primary-color;
    z-index: 1;
  }
}
