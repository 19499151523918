@import 'styles/variables';
@import 'styles/functions';
@import 'styles/mixins';

.sidePanel {
  display: flex;
  flex-direction: column;
  background-color: $gray-2;
  min-width: 370px;
  width: 370px;
  padding: spacing(3);
}

.stepTitle {
  display: flex;
  align-items: center;
  background: #FAFAFA;
  border: 3px solid $common-white;
  box-shadow: 0 4px 20px rgba(135, 189, 195, 0.2);
  font-size: $font-size-small;
  line-height: 1.5;
  font-weight: 600;
  padding: spacing(1.5) spacing(2);
  text-transform: capitalize;
}

.searchInput {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  opacity: 0;
  pointer-events: none;
  transition: opacity .2s ease-out;

  &.active {
    opacity: 1;
    pointer-events: auto;
  }
}

.mainAreaWrapper {
  flex: 1 1 auto;
  padding: spacing(3);
  display: flex;
  justify-content: center;
}

.processDescription {
  font-size: $font-size-xs;
  font-style: italic;
  line-height: 2;
}

.imgPreview {
  @include responsive-ratio(3, 4);
  min-width: 128px;

  img {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.imgPreviewThumbZoom {
  position: absolute;
  z-index: 1;
  top: spacing(0.5);
  right: spacing(0.5);
}