@import 'styles/variables';
@import 'styles/mixins';
@import 'styles/functions';

.root {
  display: flex;
  background: $common-white;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  padding: spacing(1.5);
  height: 100%;
}

.avatar {
  @include circle(72);
  margin-right: spacing(3);
}

.userInfo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-grow: 1;
  margin-right: spacing(3);
  overflow: hidden;

  .title {
    @include font-title();
    font-size: 18px;
    line-height: 1.2;
    margin-bottom: spacing(1);
    font-weight: normal;
  }
}
