@import 'variables';

// Text

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

// Images

.object-fit-cover {
  object-fit: cover;
}

.cursor-pointer {
  cursor: pointer;
}

//Position

.flex-vertical-center {
  display: flex;
  align-items: center;
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

// Color

.primary-color {
  color: $primary-color;
}

.secondary-color {
  color: $secondary-color;
}
