@import 'styles/variables';
@import 'styles/functions';
@import 'styles/mixins';

$icon-size: 50px;

.icon {
  width: $icon-size;
  height: $icon-size;
  color: $gray-1;
  margin-bottom: spacing(2.5);
}

.title {
  @include font-title();
  font-size: $font-size-small;
  text-transform: uppercase;
  user-select: none;
}

.selected {

  .icon, .title {
    color: $secondary-color;
  }
}
