@import 'styles/variables';
@import 'styles/functions';
@import 'styles/mixins';

$title-color: #FBB17B;

.root {
  background-color: $common-white;
  cursor: pointer;
  border: 1px solid $gray-2;
}

.title {
  background-color: $title-color;
  color: $common-white;
  font-weight: 600;
  line-height: 1.2;
  font-size: $font-size-big;
  padding: spacing(2) spacing(3);
}

.imgContainer {
  position: relative;
  padding: spacing(1.5);
  height: 200px;
}

.templateImg {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

.overlay {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
  pointer-events: none;
  padding: spacing(1);
}

.defaultChip {
  background-color: $primary-color;
  color: $common-white;
  font-size: $font-size-xs;
  border-radius: 100px;
  padding: spacing(0.75) spacing(1.5);
  opacity: 0;
}

.checkedIcon {
  @include circle(24);
  background-color: $primary-color;
  color: $common-white;
  opacity: 0;
  transition: opacity 0.15s ease-out;
}

.visible {
  opacity: 1;
}
