@import 'styles/variables';
@import 'styles/functions';
@import 'styles/mixins';

.root {
  @include responsive-ratio(16, 10);
  height: 100px;
  margin: 0 spacing(1);

  img {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.likeBtn {
  position: absolute;
  z-index: 1;
  bottom: spacing(2);
  left: 50%;
  transform: translateX(-50%);
  @include circle(40);
  background-color: rgba(255, 255, 255, 0.4);
  color: $common-white;
  transition: all .2s ease-out;

  &.selected {
    transform: translateX(-50%) scale(1.1);
    background-color: #FFB4B4;
  }
}
