@import 'styles/variables';
@import 'styles/functions';
@import 'styles/mixins';

.root {
    background-color: $common-white;
    padding: spacing(12) 0 spacing(16);
}

.title {
    @include font-title();
    font-size: 42px;
    line-height: 1.2;
    font-weight: normal;
    text-transform: uppercase;
    position: relative;
    padding: 0 spacing(3);
    margin-bottom: spacing(3);

    &::before, &::after {
        content: '';
        position: absolute;
        transform: translateY(-50%);
        top: 50%;
        width: 100px;
        height: 2px;
        background-color: $primary-color;
    }

    &::before {
        right: 100%;
    
    }

    &::after {
        left: 100%;
    }
}

.description {
    color: $gray-6;
    font-size: $font-size-big;
    font-style: italic;
    line-height: 1.8;
    text-align: center;
    margin-bottom: spacing(2);
}

.detail {
    background: none;
    border: none;
    padding: 0;
    color: $primary-color;
    font-style: italic;
    font-size: $font-size-big;
    line-height: 1.8;
    text-decoration: underline;
    cursor: pointer;

    &:hover {
        text-decoration: none;
    }
}

.previews {
    list-style-type: none;
    padding: 0;
    margin: 0;
    display: flex;

    li {
        padding: 0 spacing(2.5);
    }
}

.preview {
    @include font-title();
    width: 240px;
    text-align: center;
}

.previewThumbnail {
    position: relative;
    display: inline-block;
    border-radius: 50%;
    overflow: hidden;
    margin-bottom: spacing(3);
    cursor: pointer;

    &:hover {
        .previewBackdrop {
            opacity: 1;
        }
    }
}

.previewBackdrop {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 24px;
    color: $gray-2;
    padding: spacing(4.25);
    opacity: 0;

    &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: $primary-color;
        opacity: 0.8;
    }

    span {
        z-index: 1;
    }
}

.previewDescription {
    font-size: 22px;
    font-weight: normal;
    line-height: 1.5;
}

.previewTitle {
    color: $primary-color;
    font-size: 24px;
    font-weight: normal;
    line-height: 1.3;
    margin-bottom: spacing(2);
}