@import 'styles/variables';
@import 'styles/functions';
@import 'styles/mixins';

@mixin modal-title() {
  @include font-title();
  font-weight: normal;
  line-height: 2;
  text-align: center;
  text-transform: uppercase;
}

.wrapper {
  max-width: 560px;
  margin: 0 auto;
}

.title {
  @include modal-title();
  font-size: 40px;
  color: $primary-color;
}

.subtitle {
  @include modal-title();
  font-size: 24px;
}

.description {
  font-style: italic;
  line-height: 1.8;
  text-align: center;
}

.infoIcon {
  @include circle(24);
  color: $secondary-color;
  background-color: $gray-2;
}

.infoText {
  font-size: $font-size-small;
  line-height: 1.5;
}

.actionBtn {
  width: 120px;
  margin: 0 auto;
}
