@import 'styles/variables';
@import 'styles/functions';
@import 'styles/mixins';

.root {
    border-radius: 0;
    padding: 0;
}

.about {
    width: 45%;
    flex: 0 0 45%;
    padding: spacing(6.5) spacing(3) 0 spacing(4.5);
}

.title {
    @include font-title();
    text-transform: uppercase;
    font-size: 26px;
    line-height: 1.3;
    color: $primary-color;
    font-weight: normal;
    margin-bottom: spacing(1.5);
}

.description {
    font-style: italic;
    line-height: 1.7;
    margin-bottom: spacing(7);
}

.list {
    margin: 0;
    padding: 0;
    list-style-type: none;

    li {
        position: relative;
        padding-left: spacing(2.5);
        line-height: 1.8;

        &:not(:last-child) {
            margin-bottom: spacing(1);
        }

        &::after {
            content: '';
            position: absolute;
            top: 9px;
            left: 0;
            width: 9px;
            height: 9px;
            border-radius: 50%;
            background-color: $secondary-color;
        }
    }
}

.closeBtn {
    svg {
        width: 18px;
        height: 18px;
        color: $common-white;
    }
}

.formContainer {
    padding: spacing(2) spacing(3) spacing(6) spacing(4.5);
}