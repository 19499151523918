@import 'styles/variables';
@import 'styles/functions';
@import 'styles/mixins';


.root {
    max-width: 370px;
    position: relative;
    overflow: hidden;

    img {
        width: 100%;
        transition: transform 0.25s ease;
    }

    &:hover {
        img {
            transform: scale(1.1);
        }
    }
}

.text {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.title {
    color: $common-white;
    font-size: 24px;
    line-height: 1.3;
    margin-bottom: spacing(2.5);
}

.link {
    color: $common-white;

    &:hover {
        color: $primary-color;
    }

    svg {
        width: 60px;
        height: 60px;
    }
}
