@import 'styles/variables';
@import 'styles/functions';
@import 'styles/mixins';

.root {
    padding: spacing(6) spacing(3) spacing(12);
}

.container {
    width: 1350px;
    max-width: 100%;
    margin: 0 auto;
    background-color: $common-white;
    box-shadow: 20px 4px 60px rgba(0, 0, 0, 0.05);
}

.panel {
    background-color: $primary-color;
    padding: spacing(2.5) spacing(4);
    display: flex;
    align-items: center;
}

.panelLine {
    flex: 1;
    height: 1px;
    background-color: $gray-3;
    margin-right: spacing(3);
    opacity: 0.3;
}

.link {
    margin-left: auto;
    display: inline-flex;
    align-items: center;
    color: $common-white;
    border-radius: 200px;
    border: 2px solid $common-white;
    padding: spacing(1) spacing(1.5);
    transition: all 0.25s ease;

    &:hover {
        background-color: $common-white;
        color: $primary-color;
        border-color: $primary-color;
    }

    svg {
        margin-right: spacing(1);
    }
}

.imgContainer {
    position: relative;
  
    img {
      max-width: 100%;
    }
}

.formContainer {
    flex: 1;
    padding: spacing(6) spacing(5);
}

.title {
    @include font-title();
    font-size: 36px;
    line-height: 1.3;
    text-transform: uppercase;
    font-weight: 300;
    margin-bottom: spacing(2);
}

.banner {
    $banner-margin: spacing(6);
  
    position: absolute;
    width: calc(100% - #{2 * $banner-margin});
    margin: 0 $banner-margin;
    padding: spacing(2) spacing(4);
    background-color: $common-white;
    bottom: $banner-margin;
}

.bannerTitle {
    position: relative;
    background-color: $common-white;
    line-height: 1.3;
    text-transform: uppercase;
    padding: 0 spacing(2);
    z-index: 1;
}

.bannerSubtitle {
    @include font-title();
    font-size: $font-size-big;
    text-transform: uppercase;
    line-height: 1.2;
    text-align: center;
}

.crossline {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
    height: 2px;
    background-color: $secondary-color;
}