@import 'styles/variables';
@import 'styles/functions';
@import 'styles/mixins';

.root {
    width: 1710px;
    margin: 0 auto;
    padding: spacing(5);
}

.title {
    @include font-title();
    font-size: 30px;
}

.submitBtn {
    min-width: 160px;
    font-size: $font-size-big;
    align-self: center;
    margin-top: auto;
}