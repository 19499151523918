@import 'styles/variables';
@import 'styles/functions';
@import 'styles/mixins';

.lookBoardOverlay {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  background: rgba(255, 255, 255, 0.6);
}

.bottomBar {
  width: 100%;
  background-color: $common-white;
  @include px(1);
}

.userBlock {
  display: flex;
  align-items: center;
  height: 60px;
  border-bottom: 1px solid $gray-3;
}
