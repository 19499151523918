@import 'styles/variables';
@import 'styles/functions';
@import 'styles/mixins';

.root {
  background-color: $common-white;
  @include px(5);
}

.searchInput {
  position: absolute;
  right: -3px;
  width: 250px;
  top: 50%;
  transform: translateY(-50%);
  opacity: 0;
  pointer-events: none;
  transition: opacity .3s ease-out;

  &.active {
    opacity: 1;
    pointer-events: auto;
  }
}

.authLink {
  display: block;
  font-size: $font-size-small;
}

.verticalDivider {
  width: 1px;
  height: 20px;
  background-color: $gray-3;
  @include mx(2);
}

.fullName {
  font-size: $font-size-small;
  font-weight: 300;
  line-height: 1.3
}

.navLink {
  font-weight: 600;
  font-size: 20px;
  line-height: 1.2;
  padding: spacing(1);
}

.navLinkDivider {
  width: 1px;
  height: spacing(6);
  background-color: $gray-3;
}

.infoBtn {
  border: none;
  background: none;
  padding: 0;
  cursor: pointer;
  position: absolute;
  top: 100%;
  left: 0;
  padding-left: spacing(1);
  font-size: $font-size-xs;
  color: $gray-1;
  white-space: nowrap;
  line-height: 1.3;
  transition: color 0.25s ease;
  margin-top: spacing(0.5);

  &:hover {
    color: $text-color;
  }
}

.infoIcon {
  width: 14px;
  height: 14px;
  margin-right: spacing(0.5);
}
