@import 'styles/variables';
@import 'styles/functions';
@import 'styles/mixins';

.root {
  position: relative;
  background-color: $common-white;
  box-shadow: -20px 20px 40px rgba(0, 0, 0, 0.05);
  cursor: pointer;
  transition: all .15s ease-out;

  &.selected {
    background-color: $primary-color;
    color: $common-white;
  }
}

.imgContainer {
  @include responsive-ratio(16, 10);

  img {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.unselectedIcon {
  @include circle(24);
  border: 1px solid $primary-color;
}

.previewBtn {
  position: absolute;
  top: spacing(1);
  right: spacing(1);
}
