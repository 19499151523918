@import 'styles/variables';
@import 'styles/functions';
@import 'styles/mixins';

.wrapper {
  border-bottom: 1px solid $primary-color;
  padding-bottom: spacing(6);
  margin-bottom: spacing(3);
}

.root {
  position: relative;
  display: flex;
  height: 50px;
  background-color: $common-white;
  border-radius: 100px;
}

.tab {
  position: relative;
  z-index: 2;
  width: 50%;
  @include flex-center();
  color: $primary-color;
  border-radius: 100px;
  cursor: pointer;
  transition: all .2s ease-out;

  &.active {
    background-color: $primary-color;
    color: $common-white;
  }
}

.tabTitle {
  color: $text-color;
  position: absolute;
  bottom: -24px;
  font-size: $font-size-xs;
}
