@import 'styles/mixins';
@import 'styles/variables';

.root {
    border-collapse: collapse;
    width: 100%;
    
    img {
        max-width: 100%;
        display: inline-block;
    }
}

.row {
    
    &:first-child {
        .cell {
            border-top: 0;
        }
    }

    &:last-child {
        .cell {
            border-bottom: 0;
        }
    }
}

.cell {
    border: 1px solid $gray-2; 
    width: 50%;
    text-align: center;
    padding: spacing(1);

    &:first-child {
        border-left: 0;
    }

    &:last-child {
        border-right: 0;
    }
}