@import 'styles/functions';

.root {
  width: 272px;
  min-height: 100px;
  padding: spacing(3);
  margin-top: spacing(2);
  border-radius: 20px;
  box-shadow: 10px 10px 30px rgba(0, 0, 0, 0.15);

  h4 {
    font-size: $font-size-small;
    font-weight: 600;
  }
}


